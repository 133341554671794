<template>
	<div>
		<PageTitle title="检查报告" fixed backTo="/report"> </PageTitle>

		<div style="position: relative;width: 85%;margin: 15px auto auto auto;">
			<List :width="100">
				<ListItem>
					<template #top>
						<!--<p style="text-align: center;padding: 10px 0"></p>-->
						<div style="height: 10px"></div>
						<p style="text-align: center;">检查报告详情</p>

						<div class="borderColor" style="margin-top: 5px">
							<div class="leftArea">
								<p class="fontmd">检查项目：{{item.examinaim}}</p>
								<p class="fontsm">姓名：{{item.patientname}}</p>
								<p class="fontsm">检查时间：{{item.checktime}}</p>
								<van-button type="primary" size="small" style="margin-top: 10px" v-if="pdfList.length > 0" @click="cktp(pdfList[0])">查看PDF</van-button>
							</div>
						</div>
					</template>

					<template #bottom>

						<!--<p style="text-align: center;padding: 10px 0"></p>-->
						<div style="height: 10px"></div>
						<div class="borderColor" style="margin-top: 5px" v-if="pd != 3 && pd !=4 && pd != 0 ">
							<p style="text-align: left;font-size:12px">结果所见</p>
							<div class="leftArea">
								<div style="background-color: #1989fa">
									<p class="fontsm" style="text-align: center; float: left;width: 30%;">项目</p>
									<p class="fontsm" style="float: left;width:20%;text-align: center">数值</p>
									<p class="fontsm" style="float: left;width:18%;text-align: center ">单位</p>
									<p class="fontsm" style="float: left;text-align: center;width:30% ">参考范围</p>
								</div>
								<van-loading type="spinner" bottom="0" top="0" left="0" right="0" :style="display"
									style="padding-left: 50%;padding-top: 50px;" />
								<div v-for="(msgtext,index) in receiveMsgText" :key="index"
									style="clear: both;padding-top: 5px">
									<div v-if="msgtext.xmbh != '-1'">
										<p class="fontsm"
											style="float: left;width:30%;text-align: center;word-wrap: break-word">
											{{msgtext.xmmc}}
										</p>
										<p class="fontsm"
											style="float: left;width:20%;text-align: center;word-wrap: break-word">
											{{msgtext.xmjg}} {{msgtext.pd}}
										</p>
										<p class="fontsm"
											style="float: left;width:18%;text-align: center;height: 15px;word-wrap: break-word">
											{{msgtext.xmdw}}
										</p>
										<p class="fontsm"
											style="float: left;text-align: center;width:30%;word-wrap: break-word ">
											{{msgtext.ckfw}}
										</p>
									</div>
								</div>
								<div
									style="display: flex; justify-content: center; flex-wrap: wrap; text-align: center;">
									<div v-for="(msgtext,index) in base64array" :key="index" @click="imgClick(index)">
										<!-- <div v-if="msgtext.xmbh == '-1'"> -->
										<p class="fontsm">
											<img v-if="msgtext.xmbh == '-1'"
												:src="'data:image/png;base64,' + msgtext.xmjg"
												style="width:100px; height: 100px;margin: 15px;">
										</p>
										<p v-if="msgtext.xmbh == '-1'" class="fontsm">
											{{msgtext.xmmc}}
										</p>
										<!-- </div> -->
									</div>
								</div>

							</div>
						</div>
						<div class="borderColor" style="margin-top: 5px" v-if="pd == 3">
							<div>
								<van-loading type="spinner" bottom="0" top="0" left="0" right="0" :style="display"
									style="padding-left: 50%;padding-top: 50px;" />
								<div v-for="(msgtext,index) in receiveMsgText" :key="index"
									style="clear: both;padding-top: 5px">
									<p class="fontsm">提示：{{msgtext.pmome}}</p>
									<p class="fontsm">检验结果：{{msgtext.jcjg}}</p>
									<p class="fontsm">菌名：{{msgtext.bioname }}</p>
									<p class="fontsm">计数：{{msgtext.biO_QUANTITY }}</p>
									<p class="fontsm">
										药敏方法：{{ !msgtext.xjmx[0] ? '无' : msgtext.xjmx[0].method == 2 ? 'MIC法' : 'KB法' }}
									</p>
									<p class="fontsm">单位：{{msgtext.unit }}</p>
									<div v-if="msgtext.xjmx.length > 0">
										<div style="margin-top: 5px;" v-if="msgtext.xjmx[0].method == 2">
											<div class="fontsm"
												style="display:inline-block;text-align: center; float: left;width: 50%;">
												抗生素名称</div>
											<div class="fontsm"
												style="width:25%;display:inline-block;text-align: center;">实测值</div>
											<div class="fontsm"
												style="width:25%;display:inline-block;text-align: center;">敏感度</div>
										</div>
										<div style="margin-top: 5px;" v-if="msgtext.xjmx[0].method == 1">
											<div class="fontsm"
												style="display:inline-block;text-align: center; float: left;width: 50%;">
												抗生素名称</div>
											<div class="fontsm"
												style="width:12.5%;display:inline-block;text-align: center;">结果</div>
											<div class="fontsm"
												style="width:12.5%;display:inline-block;text-align: center;">解释</div>
											<div class="fontsm"
												style="width:12.5%;display:inline-block;text-align: center;">折点</div>
											<div class="fontsm"
												style="width:12.5%;display:inline-block;text-align: center;">单位</div>
										</div>
										<!--<div class="fontsm">(A组)常规、首选试验并报告的抗菌药物</div>
												<div class="fontsm">(B组)常规、但选择性报告的抗菌药物</div>
												<div class="fontsm">(C组)替代性或补充性抗菌药物</div>
												<div class="fontsm">(O组)其他对该菌群有临床适应症的抗菌药物</div>
												<div class="fontsm">(U组)仅用于或首选治疗泌尿系统感染的抗菌药物</div> -->
										<div v-for="(item,index) in msgtext.xjmx" :key="index">
											<div v-if="!item.u_CON1">
												<div class="fontsm"
													style="display:inline-block;text-align: center; float: left;width: 50%;">
													{{item.antiname}}
												</div>
												<div class="fontsm"
													style="width:25%;display:inline-block;text-align: center;"
													v-if="item.method == 2">
													{{item.micresult}}
												</div>
												<div class="fontsm"
													style="width:12.5%;display:inline-block;text-align: center;"
													v-if="item.method == 1">
													{{item.kbresult}}
												</div>
												<div class="fontsm"
													style="width:25%;display:inline-block;text-align: center"
													v-if="item.method == 2">
													{{item.testresult == 'S' ? '敏感' : item.testresult == 'I' ? '中介' : '耐药'}}
												</div>
												<div class="fontsm"
													style="width:12.5%;display:inline-block;text-align: center"
													v-if="item.method == 1">
													{{item.testresult}}
												</div>
												<div class="fontsm"
													style="width:12.5%;display:inline-block;text-align: center"
													v-if="item.method == 1">
													{{item.b_CON1}}
												</div>
												<div class="fontsm"
													style="width:12.5%;display:inline-block;text-align: center"
													v-if="item.method == 1">
													{{item.unit}}
												</div>
											</div>
										</div>
										<div v-if="msgtext.xjmx[0].u_CON1">
											<div class="fontsm"
												style="text-align: center;margin-top: 5px;font-weight: 900;"
												v-if="msgtext.xjmx.filter(ele => ele.u_CON1 == 'A').length > 0">
												(A组)常规、首选试验并报告的抗菌药物</div>
											<div v-for="(a,index) in msgtext.xjmx" :key="index">
												<div v-if="a.u_CON1 == 'A'">
													<div class="fontsm"
														style="display:inline-block;text-align: center; float: left;width: 50%;">
														{{a.antiname}}
													</div>
													<div class="fontsm"
														style="width:25%;display:inline-block;text-align: center;"
														v-if="a.method == 2">
														{{a.micresult}}
													</div>
													<div class="fontsm"
														style="width:25%;display:inline-block;text-align: center"
														v-if="a.method == 2">
														{{a.testresult == 'S' ? '敏感' : a.testresult == 'I' ? '中介' : '耐药'}}
													</div>
												</div>
											</div>

											<div class="fontsm"
												style="text-align: center;margin-top: 5px;font-weight: 900;"
												v-if="msgtext.xjmx.filter(ele => ele.u_CON1 == 'B').length > 0">
												(B组)常规、但选择性报告的抗菌药物</div>
											<div v-for="(a,index) in msgtext.xjmx" :key="index">
												<div v-if="a.u_CON1 == 'B'">
													<div class="fontsm"
														style="display:inline-block;text-align: center; float: left;width: 50%;">
														{{a.antiname}}
													</div>
													<div class="fontsm"
														style="width:25%;display:inline-block;text-align: center;"
														v-if="a.method == 2">
														{{a.micresult}}
													</div>
													<div class="fontsm"
														style="width:25%;display:inline-block;text-align: center"
														v-if="a.method == 2">
														{{a.testresult == 'S' ? '敏感' : a.testresult == 'I' ? '中介' : '耐药'}}
													</div>
												</div>
											</div>

											<div class="fontsm"
												style="text-align: center;margin-top: 5px;font-weight: 900;"
												v-if="msgtext.xjmx.filter(ele => ele.u_CON1 == 'C').length > 0">
												(C组)替代性或补充性抗菌药物</div>
											<div v-for="(a,index) in msgtext.xjmx" :key="index">
												<div v-if="a.u_CON1 == 'C'">
													<div class="fontsm"
														style="display:inline-block;text-align: center; float: left;width: 50%;">
														{{a.antiname}}
													</div>
													<div class="fontsm"
														style="width:25%;display:inline-block;text-align: center;"
														v-if="a.method == 2">
														{{a.micresult}}
													</div>
													<div class="fontsm"
														style="width:25%;display:inline-block;text-align: center"
														v-if="a.method == 2">
														{{a.testresult == 'S' ? '敏感' : a.testresult == 'I' ? '中介' : '耐药'}}
													</div>
												</div>
											</div>
											<div class="fontsm"
												style="text-align: center;margin-top: 5px;font-weight: 900;"
												v-if="msgtext.xjmx.filter(ele => ele.u_CON1 == 'O').length > 0">
												(O组)其他对该菌群有临床适应症的抗菌药物</div>
											<div v-for="(a,index) in msgtext.xjmx" :key="index">
												<div v-if="a.u_CON1 == 'O'">
													<div class="fontsm"
														style="display:inline-block;text-align: center; float: left;width: 50%;">
														{{a.antiname}}
													</div>
													<div class="fontsm"
														style="width:25%;display:inline-block;text-align: center;"
														v-if="a.method == 2">
														{{a.micresult}}
													</div>
													<div class="fontsm"
														style="width:25%;display:inline-block;text-align: center"
														v-if="a.method == 2">
														{{a.testresult == 'S' ? '敏感' : a.testresult == 'I' ? '中介' : '耐药'}}
													</div>
												</div>
											</div>
											<div class="fontsm"
												style="text-align: center; margin-top: 5px; font-weight: 900;"
												v-if="msgtext.xjmx.filter(ele => ele.u_CON1 == 'U').length > 0">
												(U组)仅用于或首选治疗泌尿系统感染的抗菌药物</div>
											<div v-for="(a,index) in msgtext.xjmx" :key="index">
												<div v-if="a.u_CON1 == 'U'">
													<div class="fontsm"
														style="display:inline-block;text-align: center; float: left;width: 50%;">
														{{a.antiname}}
													</div>
													<div class="fontsm"
														style="width:25%;display:inline-block;text-align: center;"
														v-if="a.method == 2">
														{{a.micresult}}
													</div>
													<div class="fontsm"
														style="width:25%;display:inline-block;text-align: center"
														v-if="a.method == 2">
														{{a.testresult == 'S' ? '敏感' : a.testresult == 'I' ? '中介' : '耐药'}}
													</div>
												</div>
											</div>
										</div>
									</div>
									<hr color="#3f9775" style="border-width: 0.5px;"
										v-if="index < receiveMsgText.length - 1" />
								</div>
							</div>
						</div>
						<div class="borderColor" style="margin-top: 5px;" v-if="pd == 4">
							<p style="text-align: center">
								<a style='height: 56px;margin-top:50px;border-radius: 74px;background-image: linear-gradient(90deg, #76B49C 0%, #3F9775 100%);border: 8px solid #C4D9D1;margin-left: 50px;margin-right: 50px;font-size: 15px;line-height: 40px;color: white;height: 40px;width:76px;padding: 10px 40px ;text-decoration: none;'
									@click="cktp(receiveMsgText)">查看图片</a>
							</p>
							<!-- <img :src="receiveMsgText" > -->
						</div>
						<div v-if="pd == 0" class="borderColor">
							<p class="fontsm" style="word-break: break-all;word-wrap:break-word;">
								检查所见：{{receiveMsgText.exam_rpt_cont}} </p>
							<p class="fontsm" style="padding-top: 10px;">检查诊断：{{receiveMsgText.exam_diagnose}}</p>

							<div style="height:20px">
							</div>
							<p style="text-align: center">
								<a style='height: 56px;margin-top:50px;border-radius: 74px;background-image: linear-gradient(90deg, #76B49C 0%, #3F9775 100%);border: 8px solid #C4D9D1;margin-left: 50px;margin-right: 50px;font-size: 15px;line-height: 40px;color: white;height: 40px;width:76px;padding: 10px 40px ;text-decoration: none;'
									v-if="receiveMsgText.image_url[0] && receiveMsgText.image_url[0].indexOf('该报告图像暂不支持手机端查看')==-1"
									@click="cktp(receiveMsgText.image_url[0])">查看图片</a>
							</p>
							<p
								v-if=" receiveMsgText.image_url[0] && receiveMsgText.image_url[0].indexOf('该报告图像暂不支持手机端查看')!=-1">
								该报告图像暂不支持手机端查看
							</p>
							<p style="text-align: center; margin-top: 20px;">
								<a style='height: 56px;margin-top:50px;border-radius: 74px;background-image: linear-gradient(90deg, #76B49C 0%, #3F9775 100%);border: 8px solid #C4D9D1;margin-left: 50px;margin-right: 50px;font-size: 15px;line-height: 40px;color: white;height: 40px;width:76px;padding: 10px 40px ;text-decoration: none;'
									v-if=" receiveMsgText.image_url[1] && receiveMsgText.image_url[1].indexOf('该报告图像暂不支持手机端查看')==-1"
									@click="cktp(receiveMsgText.image_url[1])">查看第二张图片</a>
							</p>
							<p
								v-if="receiveMsgText.image_url[1] && receiveMsgText.image_url[1].indexOf('该报告图像暂不支持手机端查看')!=-1">
								该报告图像暂不支持手机端查看
							</p>
						</div>
<!--						<div v-if="pdfList.length > 0">-->
<!--							<p style="text-align: center">-->
<!--								<a style='height: 56px;margin-top:50px;border-radius: 74px;background-image: linear-gradient(90deg, #76B49C 0%, #3F9775 100%);border: 8px solid #C4D9D1;margin-left: 50px;margin-right: 50px;font-size: 15px;line-height: 40px;color: white;height: 40px;width:76px;padding: 10px 40px ;text-decoration: none;' @click="cktp(pdfList[0])">查看图片</a>-->
<!--							</p>-->
<!--						</div>-->
					</template>


				</ListItem>
			</List>
		</div>
	</div>
</template>
<script>
	import PageTitle from '@/components/PageTitle.vue';
	import List from '@/components/home-page/List.vue';
	import ListItem from '@/components/home-page/ListItem.vue';
	import wx from 'weixin-js-sdk';
	import {
		ImagePreview
	} from 'vant';
	import {
		GetCYTMH,
		GetRecordMx,
		GetExamDetails,
		GetestLists,
		GetRecordMX_WSW,
		GetZYOtherReportDetails,
	} from '@/api/check.js';
	import Vue from 'vue';
	import {
		Loading
	} from 'vant';
	Vue.use(Loading);
	export default {
		components: {
			PageTitle,
			List,
			ListItem,
		},
		created: function() {
			this.Hqxq();
			// this.hide();
			console.log(this.$route.params)
		},
		//        beforeMount:function () {
		//           this.display.display='none'
		//        },

		data() {
			return {
				tmh: this.$route.params.tmh,
				patient_sn: this.$route.params.patientSn,
				receiveMsgText: {
					audit_user_name: "",
					brxm: "",
					exam_diagnose: "",
					exam_name: "",
					exam_order_name: "",
					exam_reporter: "",
					exam_rpt_cont: "",
					exam_time: "",
					image_url: [],
					url: [],
				},
				smdh: "",
				indexs: "1",
				item: [],
				display: {
					display: ""
				},
				pd: this.$route.params.pd,
				routeParams: this.$route.params,
				base64array: [],
				pdfList: [],
			}
		},
		methods: {
			cktp(e) {
				let that = this
				if(this.pd == 4) {
					window.location.replace(e)
				} else {
				wx.miniProgram.redirectTo({
					url: `/pages/webview/webview?download=${e}&backTo=#/reportsm/${that.routeParams.xmmc}/${that.routeParams.xm}/${that.routeParams.rq}/${that.routeParams.txm}/${that.routeParams.pd}/${that.routeParams.sampleno}`
				});
				// that.$destroy()
				}
			},
			Hqxq() {

				if (this.tmh != undefined) {
					if (this.tmh.length == 10) {
						// this.base64array = [];
						GetCYTMH(this.tmh).then(
							(result) => {
								GetRecordMx(result[0], this.patient_sn).then(
									(resulta) => {
										this.receiveMsgText = resulta.checkList;
										this.pdfList = resulta.pdfList;
										this.base64array = resulta.checkList.filter(item => {
											return item.xmbh == "-1";
										})
										for (let i = 0; i < this.receiveMsgText.length; i++) {
											this.receiveMsgText[i].pd = this.PD(this.receiveMsgText[i].jgpd);
										}
										() => {}
									}
								);

								var _this = this;
								_this.gettime = this.$util.moment().format('YYYY-MM-DD HH:mm:ss');
								//                _this.gettimes = yys + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss;
								_this.gettimes = this.$util.moment().subtract(2, 'years').format(
									'YYYY-MM-DD HH:mm:ss');
								GetestLists(_this.gettimes, _this.gettime, result[0], 0).then(
									(resultss) => {
										this.item = resultss[0]
										this.item.checktime = this.$util.moment(this.item.checktime)
											.format(
												"YYYY-MM-DD HH:mm:ss");

										this.display.display = 'none'
										//                                     this.display.display='none'
									},
									() => {
										this.display.display = 'none'
									},
								);
							},
							() => {}
						);
					}
					//                    this.$nextTick(function(){
					//                        this.display.display='none'
					//                    })
					else {
						var _this = this;
						_this.gettime = this.$util.moment().format('YYYY-MM-DD HH:mm:ss');
						//                _this.gettimes = yys + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss;
						_this.gettimes = this.$util.moment().subtract(2, 'years').format(
							'YYYY-MM-DD HH:mm:ss');
						GetestLists(_this.gettimes, _this.gettime, _this.tmh, 0).then(
							(resultss) => {
								this.item = resultss[0]
								this.item.checktime = this.$util.moment(this.item.checktime).format(
									"YYYY-MM-DD HH:mm:ss");
								this.display.display = 'none'
								GetRecordMx(resultss[0].doctadviseno, this.patient_sn).then(
									(resulta) => {
										this.receiveMsgText = resulta.checkList;
										this.pdfList = resulta.pdfList;
										this.base64array = resulta.checkList.filter(item => {
											return item.xmbh == "-1";
										})
										for (let i = 0; i < this.receiveMsgText.length; i++) {
											this.receiveMsgText[i].pd = this.PD(this.receiveMsgText[i]
												.jgpd);
										}
									},
									() => {}
								);
								//                                     this.display.display='none'
							},
							() => {
								this.display.display = 'none'
							},
						);

					}
				} else {
					if (this.$route.params.pd == '1' || this.$route.params.pd == '2') {
						this.item = {
								patientname: this.$route.params.xm,
								examinaim: this.$route.params.xmmc,
								checktime: this.$route.params.rq,
							},
							this.tmh = this.$route.params.txm;
						// this.base64array = [];
						GetRecordMx(this.tmh, this.patient_sn).then(
							(result) => {
								this.receiveMsgText = result.checkList;
								this.pdfList = result.pdfList;
								this.base64array = result.checkList.filter(item => {
									return item.xmbh == "-1";
								})
								for (let i = 0; i < this.receiveMsgText.length; i++) {
									this.receiveMsgText[i].pd = this.PD(this.receiveMsgText[i].jgpd);
								}
								this.display.display = 'none'
								//                            this.display.display='none'
							},
							() => {
								this.display.display = 'none'
							}
						)
					} else if (this.$route.params.pd == '0') {
						this.item = {
								patientname: this.$route.params.xm,
								examinaim: this.$route.params.xmmc,
								checktime: this.$route.params.rq,
							},
							this.tmh = this.$route.params.txm;
						GetExamDetails(this.tmh).then(
							(result) => {
								this.receiveMsgText = result;
								// this.base64array = result.filter(item => {
								// 	return item.xmbh == "-1";
								// })
								for (let i = 0; i < this.receiveMsgText.length; i++) {
									this.receiveMsgText[i].pd = this.PD(this.receiveMsgText[i].jgpd);
								}
								this.display.display = 'none'
								//                            this.display.display='none'
							},
							() => {
								this.display.display = 'none'
							}
						);
					} else if (this.$route.params.pd == '3') {

						this.item = {
								patientname: this.$route.params.xm,
								examinaim: this.$route.params.xmmc,
								checktime: this.$route.params.rq,
							},
							this.tmh = this.$route.params.txm;
						GetRecordMX_WSW(this.tmh).then(
							(res) => {
								this.receiveMsgText = res;
								console.log(this.receiveMsgText)
								this.display.display = 'none';
							},
							() => {
								this.display.display = 'none';
							}
						)
					} else if (this.$route.params.pd == '4') {
						console.log(11);
						this.item = {
								patientname: this.$route.params.xm,
								examinaim: this.$route.params.xmmc,
								checktime: this.$route.params.rq,
							},
							GetZYOtherReportDetails(this.$route.params.sampleno).then(res => {
									this.receiveMsgText = res;
									this.display.display = 'none';
								},
								() => {
									this.display.display = 'none';
								})
					}
				}
			},
			PD(e) {
				if (e == "H") {
					return '↑';
				} else if (e == "L") {
					return '↓';
				} else {
					return null;
				}
			},
			hide() {
				//                this.display.display='none'
			},
			// 图片预览
			imgClick(index) {
				console.log(index)
				let arr = this.receiveMsgText.filter(item => {
					return item.xmbh == -1;
				})
				console.log(arr);
				ImagePreview({
					images: arr.map(item => {
						return 'data:image/png;base64,' + item.xmjg;
					}),
					startPosition: index,
					onClose() {}
				})
			}

		}
	}
</script>

<style scoped="" lang="less">
	@import "../style/var.less";

	.infoContainer {
		padding-bottom: 10px;

		p {
			margin: 0;
		}

		.borderColor {
			background-color: #f6f6f6;
			border-radius: 8px;
			padding: 10px;
			margin: 0 10px;

			.leftArea {
				width: 100%;
				display: inline-block;

			}

			.rightArea {
				width: 15%;
				display: inline-block;
				vertical-align: top;

				p {
					float: right;
				}

				.van-icon {
					float: right;
					margin-top: 40px;
				}
			}
		}

		.fontmd {
			font-size: @font-size-md;
		}

		.fontsm {
			font-size: @font-size-sm;
		}
	}
</style>
